import React from 'react';
import logo from './resources/logo.png';
import './App.css';
import AppPhone from './phone/AppPhone';
import { BrowserView, MobileView } from 'react-device-detect';
import AppWeb from './web/AppWeb';
import { ToastContainer } from 'react-toastify';
import { LanguageProvider } from './language/LanguageProvider';

const dd = require('./font/FZYouSJW_509R.TTF')

function App() {
  return (
    <LanguageProvider>
      <div className='App'>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark" />


        {/* <AppPhone /> */}
        <BrowserView>
          <link rel="preload" as="font" href="https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/FZYouSJW_509R.TTF" crossOrigin = ""/>
          <AppWeb />
        </BrowserView>
        <MobileView>
          <AppPhone />
        </MobileView></div></LanguageProvider>
  );
}

export default App;
