export const CHINESE_CONTENT = {
  "welcome": "欢迎",
  "hello": "你好",
  "goodbye": "再见",
  "====== c1 ========": "",
  "======= c1 =======": "",
  "======== c1 ======": "",
  "========= c1 =====": "",
  "cha1_c1": "兄弟，你有多久没赚到钱了？",
  "cha1_c2": "如果很久了，",
  "cha1_c3": "点这儿",
  "cha1_c4": "，我给你点儿。",
  "cha1_c5": "如果你觉得我高高在上，有点装逼，请不要误会。",
  "cha1_c6": "我和你一样，只想拿回我们失去的。",
  "cha1_c7": "让时间回到2004年。那时的Facebook刚刚诞生，而另一家社交平台MySpace早已风靡全美。两者的功能没有本质区别，不同点在于早期用户。酒吧销售和脱衣舞娘总是在MySpace发布色情和诈骗信息，而“哈佛学生都在用”却成了Facebook一次次破圈增长的金字招牌。靠着藤校用户营造出的良好生态，没过几年，Facebook把老大哥挑落马下。",
  "cha1_c8": "2006年3月21号，伴随着Jack Dorsey的第一条推文，推特上线。但一年过去了，它只有四万五千个用户，其中多数还不常登陆。直到SXSW大会，一群geek突然发现了推特的正确用法，他们用手机发推直播起盛会实况——“隔壁的讲座真棒！”“那边有名人可以合影”……不想错过精彩的人们开始纷纷加入，一场疯狂的用户自传播从此改变了推特的命运。",
  "cha1_c9": "6年后，在短视频这片新战场，坐拥3000万美金的Vine更喜欢和他的大咖朋友们一起玩，流量总是向名人倾斜，普通人的作品很难被看到。大洋彼岸，一没钱二没资源的Musical.ly却把普通用户的随手创作放到了产品首页，而用户从不让人失望。从“对口型大赛”到“Don't Judge 挑战”，这帮家伙一次次刷新了人们对短视频的想象力边界。没过多久，Musical.ly被用户送上了下载榜第一，而它也有了个更响亮的新名字——TikTok。",
  "cha1_c10": "我在深夜翻看着一本本企业传记，透过财经媒体上胜者为王的嘈杂声找到这些故事，我试着用另一种方式把它讲给你听。我希望你看到的不是天才创始人的一帆风顺，也不是资本大鳄的慧眼独具，我讨厌为超级公司们的丰功伟绩再描几笔，但如果你能把“用户”锁定为这些故事的主角，那一切都是值得的。你已经看到了奇迹的创造者究竟是谁、看到了“用户”不是空洞的概念而是鲜活的人，我还希望你看到，那群被称作用户的人们，正是你和我。",
  "cha1_c11": "是我们，用户，让每个产品长大，直到她大得不再多看我们一眼",
  "cha1_c12": "是我们，用户，让每间公司成功，即便他的喜悦从不主动与我们分享",
  "cha1_c13": "是我们，用户，书写着一个又一个财富故事，成就了一批又一批亿万富翁",
  "cha1_c14": "但我们，可曾得到过什么？",
  "cha1_c15": "我和你一样，只想拿回我们失去的。",
  "cha1_c16_1": "此刻，有\u00A0",
  "cha1_c16_1_p": "此刻，有",
  "cha1_c16_2": "\u00A0人正和你在同一时间读着这段文字，在UserDAO发布至今的\u00A0",
  "cha1_c16_2_p": "人正和你在同一时间读着这段文字，在UserDAO发布至今的",
  "cha1_c16_3": "\u00A0个小时里，",
  "cha1_c16_3_p": "个小时里，",
  "cha1_c16_4": "\u00A0位伙伴加入了我们。一群从没得到回报的财富创造者开始从不同时区出发，他们在UserDAO集结。他们正试着共同努力，找到一种方法，把财富还给用户，把财富还给自己。",
  "cha1_c16_4_p": "位伙伴加入了我们。一群从没得到回报的财富创造者开始从不同时区出发，他们在UserDAO集结。他们正试着共同努力，找到一种方法，把财富还给用户，把财富还给自己。",
  "cha1_c17": "你应该",
  "cha1_c18": "点这儿",
  "cha1_c19": "，取走那笔本属于你的。",
  "cha1_p1": "Facebook用户和MySpace用户风格迥异的个人主页",
  "cha1_p2": "此前默默无闻的Twitter在SXSW大会一举夺得Interactive Web Awards",
  "cha1_p3": "Musical.ly在2018年8月正式融入TikTok",
  "====== c2 ========": "",
  "======= c2 =======": "",
  "======== c2 ======": "",
  "========= c2 =====": "",
  "cha2_c1": "就在上周，我的好朋友Julia失业了。我和她有过两年共事，她不算天才，但绝对称得上富有才华的原画师。你或许猜到了，拿走她工作的不是另一位原画师，而是可以24小时不眠不休的Midjourney V5。是的，从AI中看到的一切让我产生了一种更深的感受：把财富还给用户，愈加迫切了。",
  "cha2_c2": "登陆网络账号时常用的真人识别系统reCAPTCHA，你应该对它并不陌生。但你或许不知道，这里每个需要你提供判断的指路牌、斑马线和十字路口都是reCAPTCHA的AI模型尚不能准确识别的内容，你的每一次真人验证，都在帮助AI标注数据，让它变得更加有效。",
  "cha2_c3": "其实，图像标注已经是reCAPTCHA为我们安排的第二个任务了。在它的V1阶段，从2007到2018，我们通过在真人验证时输入单词，帮助计算机完成了人类1.3亿册书籍中超过1/5内容的线上录入。而人类书籍，正是GPT系列模型中重要的数据来源。",
  "cha2_p1": "reCAPTCHA正要求用户标出图中所有的道路标志",
  "cha2_p2": "GPT-3数据来源：WebText2是一个由Reddit高分帖子中引用的链接组成的数据集",
  "cha2_c4": "根据OpenAI的官方论文，除了书籍，GPT的训练数据中还有3%来自维基百科，22%源于Reddit。如果你也曾在reCAPTCHA上填写过验证码、在Wikipedia上审阅过词条、在Reddit发布过帖子，你会为自己对技术浪潮的贡献感到骄傲吗？说实话，我会。但与此同时，当ChatGPT Plus向我收取每月20$的升级费用，当我面对OpenAI要实现 2万亿利润的豪言壮语，我仿佛又想起了Facebook、Twitter和TikTok们尚未完结的故事，又看到了财富创造者一无所获的身影。",
  "cha2_c5": " 把财富还给用户，把财富还给自己——我深知，凡提出有利于多数人的主张，总是更容易收获赞许和支持，但至少此刻，我仍希望我们都不丢掉审慎，我还想与你一道，再次看看这个世界。",
  "cha2_c6": "我们看到18世纪的英国，那里刚刚诞生了人类最初的知识产权保护制度，科学家开始从自身的科研成果中受益，第一次工业革命由此萌芽。",
  "cha2_c7": "我们看到19世纪的日本，明治维新废除了封建土地制度，农民耕种的作物终于归本人所有，一举让日本农业高速增长20年。",
  "cha2_c8": "我们看到20世纪的中国，改革开放让私营企业主一步步获得了自己企业的所有权，也让一个深刻影响世界的经济奇迹来到世人面前。",
  "cha2_c9": "我们看到，在人类社会的一段段历史中，每当把财富还给它的创造者，获得激励的人们就总能有条件、有动力创造更大的福祉。",
  "cha2_c10": "我们还看到，今天的我们，数字时代的用户，就像曾经“不被保护的发明家”“没有土地的农民”“不许赚钱的老板”，同样面对着自己创造却不能享有的财富，同样呼唤着一场生产关系的变革。",
  "cha2_c11": "这种生产关系是更加公平的，它能正视用户的数据和使用在数字时代带来的巨大生产力，给予我们公正的回报。",
  "cha2_c12": "这种生产关系是提升效率的，它通过有效激励财富的创造者，把用户、产品、公司凝聚为相向而行的共同体，为社会创造更大福祉。",
  "cha2_c13": "这种生产关系是安全可靠的，它原生于数字世界本身，用信息技术的方式保障，颠扑不破。",
  "cha2_c14": "宏大的时代命题，总是不会一蹴而就。我们相信，数字时代崭新生产关系的终极答案，要在未来一到两个十年，依靠许多人在许多方面的努力来共同揭晓。但我们更明白，改变从不因等待发生。因此：",
  "cha2_c15": "我们发起了UserDAO，希望从“把财富还给用户”出发，凝聚先行者们的力量。",
  "cha2_c16": "我们创建了User Labs，希望它打造的产品能与用户一道，探索更有效率的财富分配新范式。",
  "cha2_c17": "我们设计并编码了UWT，一种与产品营收绑定、用户凭使用获得、价格只涨不跌的新型通证，将它作为把财富还给用户的工具。",
  "cha2_c18": "是的，就是你刚刚领到的UWT。",
  "cha2_c18_1": "是的，你已持有\u00A0",
  "cha2_c18_2": "\u00A0个的UWT。",
  "cha2_c19": "请点此领取更多",
  "====== c3 ========": "",
  "======= c3 =======": "",
  "======== c3 ======": "",
  "========= c3 =====": "",
  "cha3_c1": "目录",
  "cha3_m1": "概述",
  "cha3_m2": "直觉引入",
  "cha3_m3": "机制",
  "cha3_m3_1": "全局参数",
  "cha3_m3_2": "UWT单价公式",
  "cha3_m3_3": "UWT发行公式",
  "cha3_m3_3_1": "局部参数",
  "cha3_m3_3_2": "发行公式",
  "cha3_m3_3_3": "初始函数",
  "cha3_m3_3_4": "数值举例-1",
  "cha3_m3_4": "UWT兑换公式",
  "cha3_m3_4_1": "局部参数",
  "cha3_m3_4_2": "兑换公式",
  "cha3_m3_4_3": "初始函数",
  "cha3_m3_4_4": "数值举例-2",
  "cha3_m3_5": "合约查询",
  "cha3_m4": "生态",
  "cha3_m4_1": "User Labs",
  "cha3_m4_2": "兄弟产品接入",
  "cha3_m5": "结论与愿景",
  "cha3_c2": "User Wealth Token（UWT）是数字时代生产力巨变背景下革新生产关系的核心工具。该工具的唯一目标是创建一个自我维持的将财富归还于用户的经济系统，它在诞生之初就杜绝金融市场对用户财富的剥削和威权对用户财富的干预 —— ",
  "cha3_c3": " UWT是用户凭使用产品获得、单价只涨不跌（以ETH计价）、随时与ETH刚性兑换的链上资产。",
  "cha3_c4": "在这个经济系统中，UWT和UWT金库是至关重要的。",
  "cha3_c5": "UWT金库是由UserDAO发起的链上ETH金库，它将收取所有参与UWT生态的产品的注资，以支撑UWT价格、指导UWT发行、执行UWT兑换。",
  "cha3_c6": "参与UWT生态的产品，通过向UWT金库注资获得UWT并发放给其用户，以此将财富还给数字时代生产力的真正基石——用户。UserDAO的发起者之一 —— User Labs，会将旗下全部产品接入UWT生态，并把每个产品营收的51%按合约注入UWT金库。",
  "cha3_c7": "我们将在下文中详细介绍UWT和UWT金库如何通过协同，构建实现如上目标的经济系统。",
  "cha3_c8": "想象一个耗时3天的极简情况，以建立UWT只涨不跌的直觉。",
  "cha3_c9": "第1天，User Labs向UWT金库初始注资3 ETH，UWT创世发行3枚。",
  "cha3_c10": "根据合约，",
  "cha3_c11": "，持有UWT的用户可按此价格随时进行兑换。",
  "cha3_c11_p": "持有UWT的用户可按此价格随时进行兑换。",
  "cha3_c12": "创世时，1 UWT=1 ETH。",
  "cha3_c13": "第2天，User Labs将2 ETH注入UWT金库。每当金库新增资产，都需新发行UWT以回报用户的使用，且\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0。极简地，新发行1枚UWT满足要求。",
  "cha3_c13_p": "第2天，User Labs将2 ETH注入UWT金库。每当金库新增资产，都需新发行UWT以回报用户的使用，且",
  "cha3_c13_p2": "极简地，新发行1枚UWT满足要求。",
  "cha3_c14": "新发行后，1 UWT = 1.25 ETH，涨幅25%。",
  "cha3_c15": "第3天，某用户发起3枚UWT的兑换。为了保护长期持有UWT的用户的利益，降低短期投机客及洗钱行为的影响，我们需要对大额/高频的兑换设置税费。极简地，此处设为10%。该用户顺利销毁3枚UWT，兑换3.375 ETH，同时向金库缴纳了 3.75 ETH × 10% = 0.375 ETH 税费。全部税费都将留在UWT金库中用以支撑剩余UWT的价格上涨。",
  "cha3_c16": "以上大额兑换后，1 UWT=1.625 ETH，涨幅30%。",
  "cha3_c17": "如果说USDT是一种锚定美元的稳定币(Stablecoin)，那你完全可以将UWT看作锚定ETH的恒升币(Risingcoin)。",
  "cha3_c18": "UWT单价",
  "cha3_c19": "UWT金库总资产，计价单位为ETH",
  "cha3_c20": "UWT现行总量",
  "cha3_c21": "持有UWT的总地址数",
  "cha3_c22": "在每一次执行UWT发行或UWT销毁前，合约将根据如上公式更新UWT单价。",
  "cha3_c23": "UWT金库收到新的ETH汇款后，将自动调用合约中的UWT发行代码，进行新一轮的UWT发行，也就是说，凡有新营收必有新UWT用于激励新的使用。同时，UWT发行公式将确保每次发行后，UWT单价始终上涨。",
  "cha3_c24": "UWT金库新增资产，计价单位为ETH",
  "cha3_c25": "UWT新发行量",
  "cha3_c26": "UWT发行指数，δ ∈ [10%, 90%]。计算公式由UserDAO成员治理决定",
  "cha3_c26_p": "UWT发行指数，δ∈[10%,90%]。计算公式由UserDAO成员治理决定",
  "cha3_c27": "UWT金库增幅指数， α ∈ (0, 0.5%]。计算公式由UserDAO成员治理决定",
  "cha3_c27_p": "UWT金库增幅指数，α∈(0,0.5%]。计算公式由UserDAO成员治理决定",
  "cha3_c28": "发行指数δ与金库增幅指数α将高度影响UWT新发行数量∆I。这两个参数的计算公式将由UserDAO成员共治决定，我们建议UserDAO成员在治理时综合考虑能够反映UWT影响力的各项指标，例如，持有UWT的地址数与UWT金库增长率。为此，我们设置了如下初始状态的发行指数δ与增幅指数α。  ",
  "cha3_c28_p": "发行指数δ与金库增幅指数α将高度影响UWT新发行数量∆I。这两个参数的计算公式将由UserDAO成员共治决定，我们建议UserDAO成员在治理时综合考虑能反映UWT影响力的各项指标，例如，持有UWT的地址数与UWT金库增长率。为此，我们设置了如下初始状态的发行指数δ与增幅指数α。  ",
  "cha3_c29": "δ的初始函数：",
  "cha3_c30": "α的初始函数：",
  "cha3_c31": "UWT金库中有313.95个ETH，UWT合约记录现行UWT总量为24150枚，持有UWT总地址数为100000个。",
  "cha3_c32": "此时UWT单价",
  "cha3_c33": "发行指数",
  "cha3_c34": "现金库收到25 ETH的打款，增幅指数",
  "cha3_c35": "那么，UWT新发行量",
  "cha3_c36": "发行后，UWT金库中有",
  "cha3_c37": "现行UWT总量为",
  "cha3_c38": "UWT新单价",
  "cha3_c39": "，币价涨幅3.85%",
  "cha3_c39_p": "币价涨幅3.85%。",
  "cha3_c40": "UWT随时刚性兑付，即UWT持有者可以随时调用合约中的UWT销毁代码，将持有的UWT进行数值自定义的销毁，并根据兑换公式从UWT金库中提现ETH。",
  "cha3_c41": "提现金额，计价单位为ETH",
  "cha3_c42": "UWT销毁量，UWT持有人发起兑换时选择销毁的UWT数量",
  "cha3_c43": "UWT兑换时间间隔，即某次兑换发起时，其距离上一次兑换的时间间隔，单位为秒",
  "cha3_c44": "UWT兑换税率， β ∈ (0, 10%]。计算公式由UserDAO成员治理决定",
  "cha3_c45": "UWT兑换税，UWT持有人在提现时需向金库缴纳的税金",
  "cha3_c46": "由上，可以得到UWT兑换税的计算公式：T = P × B × β。税金UserDAO分文不取，其在金库中用以提升UWT的单价。",
  "cha3_c47": "兑换税率β将高度影响兑换发生后的UWT单价提升幅度。β的计算公式由UserDAO成员共治决定，我们建议UserDAO成员在治理时应关注用户正常的兑换行为与其他不利于UWT生态的兑换行为的不同，实施区别征税。例如，用户的正常兑换应是正常频率下的随机行为，而短期投机客的兑换则会出现集中高频大额的特点。为此，我们设置了如下初始状态的兑换税率β。",
  "cha3_c48": "β的初始函数：",
  "cha3_c49": "接数值举例-1，UWT金库中有338.95ETH，UWT合约记录现行UWT总量为25106.75枚。",
  "cha3_c50": "UWT单价",
  "cha3_c51": "现有某UWT持有人发起UWT兑换，销毁的UWT数量为1000枚，距上次兑换间隔1小时。",
  "cha3_c52": "此时，兑换税率",
  "cha3_c53": "则兑换金额",
  "cha3_c54": "而兑换税",
  "cha3_c55": "被留在金库以提升UWT单价。",
  "cha3_c55_p": "被留在金库以提升UWT单价。",
  "cha3_c56": "兑换后，UWT金库中有326.233 ETH，现行UWT总量为24106.75枚。",
  "cha3_c56_p": "兑换后，UWT金库中有326.233 ETH，现行UWT总量为24106.75枚。",
  "cha3_c57": "UWT新单价",
  "cha3_c58": "，币价涨幅0.24%",
  "cha3_c58_p": "币价涨幅0.24%。",
  "cha3_c59": "这里是User Labs产品的收款合约地址\u00A0",
  "cha3_c59_p": "这里是User Labs产品的收款合约地址",
  "cha3_c60": "，你应该于此检验：",
  "cha3_c61": "User Labs旗下产品营收的51%注入UWT金库",
  "cha3_c62": "这里是UWT的合约地址\u00A0",
  "cha3_c62_p": "这里是UWT的合约地址",
  "cha3_c63": "，你应该于此检验：",
  "cha3_c64": "UWT的新发行必由新资金注入触发",
  "cha3_c65": "新发行后UWT单价必然上涨",
  "cha3_c66": "兑换发生后UWT单价必然上涨",
  "cha3_c67": "没有任何人，能通过销毁UWT以外的方式从金库提现",
  "cha3_c68": "User Labs是一间为支持UserDAO发起而设立的产品工作室。作为UserDAO主张的最初践行者，User Labs致力于在数字时代打造一系列用户喜爱且能通过使用获得财富的优质产品。同时为在UWT生态中发挥其可能的最大价值，User Labs旗下产品的全部营收将终身按如下方式分配：",
  "cha3_c69": "51%注入UWT金库",
  "cha3_c70": "39%注入UserDAO Investment Protocol（UIP）。UIP在现阶段的首要任务是积累资金以支持UserDAO治理代币USER的发行。代币发行后，UIP的资金将通过UserDAO成员共治的方式对积极参与UWT生态的兄弟产品进行投资，以推动“将财富还给用户”和UWT生态在更大范围的采用",
  "cha3_c71": "10%用于User Labs旗下产品运营",
  "cha3_c72": "任何认可“将财富还给用户”的产品团队，均可以在UserDAO发起产品接入提案。我们建议在提案中说明UWT金库注资额与其获得UWT后对用户的发放规则。UserDAO成员对提案投票，提案获得通过后，该产品可以获得UWT金库注资权限，通过向UWT金库注资获得UWT，并将UWT发放给它的用户。",
  "cha3_c73": "我们相信：",
  "cha3_c74": "UWT作为锚定ETH的恒升币(Risingcoin)，其空投和激励价值超过ETH",
  "cha3_c75": "选择接入UWT的产品将立即获得UserDAO全体成员的支持与使用",
  "cha3_c76": "通过践行“将财富还给用户”这一主张，任何产品都将在与其同类对手的竞争中获得优势，赢得用户的支持",
  "cha3_c77": "UWT提供了一种用户通过使用即可积累的、单价只涨不跌的、可随时刚性兑换为ETH的链上财富。相较于其他通证，UWT可以实现：",
  "cha3_c78": "产品获得的财富由全体用户真实、持续地共享",
  "cha3_c79": "用户通过使用即可积累财富，无需投资、购买或付出任何经济成本",
  "cha3_c80": "用户的财富不受“币价”波动的剥削，用户的使用不受金融周期扰乱",
  "cha3_c81": "我们相信，一切依靠用户间网络效应创造财富的产品，都能通过参与UWT生态向全体用户与全体潜在用户传递明确的价值观——我们认可用户使用的价值。把财富还给用户将使产品获得更多、更富黏性、更具创造力的用户和更友好、更负责的使用，这些产品将在生产力巨变的数字时代走向更大的成功。我们从User Labs推出的产品开始践行并证实这一主张。",
  "cha3_c82": " 我们迫不及待地希望在UWT生态中，通过使用产品，获得财富、公平、自由。",
  "cha3_c83": "请点此领取更多UWT。",
  "====== c4 ========": "",
  "======= c4 =======": "",
  "======== c4 ======": "",
  "========= c4 =====": "",
  "cha4_c1": "这是本轮空投结束的倒计时，也是你手中",
  "cha4_c1_1": "枚",
  "cha4_c2": "UWT揭晓最终价格的时刻——只涨不跌的UWT发行机制，我们将立刻用到它。",
  "cha4_c3_1": "UWT与ETH的自由兑换将在计时结束后开启，而在此期间，每新增1000位UWT持有者，我都将为金库额外充值2 ETH。这意味着，更多人加入我们，就有更大的UWT金库，就有更高的UWT价格，就有每个人更多的财富。截至现在，前1000位UWT持有者已经获得了\u00A0",
  "cha4_c3_1_p": "UWT与ETH的自由兑换将在计时结束后开启，而在此期间，每新增1000位UWT持有者，我都将为金库额外充值2 ETH。这意味着，更多人加入我们，就有更大的UWT金库，就有更高的UWT价格，就有每个人更多的财富。截至现在，前1000位UWT持有者已经获得了",
  "cha4_c3_2": "\u00A0的价格涨幅，而我，正迫不及待为你和你的朋友继续充值。创世价格由每个人共同决定，我们唯一没有的，是涨幅上限。",
  "cha4_c3_2_p": "的价格涨幅，而我，正迫不及待为你和你的朋友继续充值。创世价格由每个人共同决定，我们唯一没有的，是涨幅上限。",
  "cha4_c4": "我相信用户的力量，相信我们正被错误的财富分配方式困于牢笼，枷锁一旦砸碎，洪水会冲刷出新的沃土。",
  "cha4_c5": "我相信行业的力量，相信Web 3.0终将不再与“镰刀”和“韭菜”为伍，相信她会通过重塑公平有效的生产关系，为世界带来改变。",
  "cha4_c6": "我相信技术的力量，相信昨天的异想天开、今天的勉为其难都会成为明天的习以为常，相信更高效可靠的去中心化方案属于生活在数字时代的每个人。",
  "cha4_c7": "我相信行动的力量。当一个人不知道脚下的路会把自己引向何方，这意味着他已经攀登得比任何时候更高了。我相信我们的双手，相信我们已经一同开启的旅程，相信“把财富还给用户”的声音，值得被更多人听到。",
  "cha4_c8": "Share the manifesto with your family and friends",
  "cha4_c8_p": "Share the Manifesto",
  "====== egg ========": "",
  "======= egg =======": "",
  "======== egg ======": "",
  "========= egg =====": "",

  "egg_tips": "别急～蛋蛋还孵着呢...",
  "egg_c1": "Sizzle是个平平淡淡的推特插件 它非常温和",
  "egg_c2": "主要功能是帮普通人去大V的推特主页上搞游行",
  "egg_c3": "嗯 游行",
  "egg_c3_1": "把我们想说的 插在它的主页",
  "egg_c3_2": "像一条醒目的横幅 占领这片土地",
  "egg_c4": "广场上的大电视 滚动播放你的宣言",
  "egg_c5": "来往的人们不断加入",
  "egg_c6": "要一起把面具砸烂",
  "egg_c7": "虚伪的嘴脸趾高气昂",
  "egg_c8": "有趣的人们默默无闻",
  "egg_c9": "无聊的现状渴望着变化",
  "egg_c10": "有些人不喜欢声音永远挤在评论区",
  "egg_c11": "普通人不该只有点赞或沉默的权利",
  "egg_c12": "当空投结束，游戏正式开始",
  "egg_c13": "*Sizzle将51%的营收注入UWT金库，支持UWT的涨价和新发行。",
  "egg_c14": "它将在倒计时结束上线，你可以通过",
  "egg_c15": "了解关于Sizzle的更多",

  "egg_c13_p": "*Sizzle将51%的营收注入UWT金库，支持UWT的涨价和新发行。它将在倒计时结束上线，你可以通过",
}