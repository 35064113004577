export const ENGLISH_CONTENT = {
  "welcome": "Welcome",
  "hello": "Hello",
  "goodbye": "Goodbye",
  "====== c1 ========": "",
  "======= c1 =======": "",
  "======== c1 ======": "",
  "========= c1 =====": "",
  "cha1_c1": "My fren, when was the last time you made some bucks?",
  "cha1_c2": "If it's been too long, hit\u00A0",
  "cha1_c3": "this link",
  "cha1_c4": "\u00A0and let me spare you some.",
  "cha1_c5": "And hey, if you think I'm being all high and mighty, don't get me wrong. ",
  "cha1_c6": "Like you, I just want to get back what we have lost.",
  "cha1_c7": "Let's travel back to 2004, when Facebook had just been founded. Meanwhile, another social platform, MySpace, had already taken America by storm. Both platforms had similar features, but their early users were different. MySpace was filled with pornography and scams posted by bar salespeople and strippers, while Facebook's association with Harvard students was the golden ticket to its success. Thanks to the thriving ecosystem created by its Ivy League users, Facebook knocked its rival off its pedestal within a few short years.",
  "cha1_c8": "On March 21, 2006, Twitter launched with Jack Dorsey's first tweet. A year later, the platform had only 45,000 users, most of whom were not active. It wasn't until the SXSW conference that a group of geeks discovered the correct way to use Twitter. They began to tweet live broadcasts of the conference, such as \"The keynote next door is great!\" and \"There are celebrities over there for photos\". Those who didn't want to miss out on the excitement started to join in, creating a crazy self-propagating user base that transformed Twitter's fortunes.",
  "cha1_c9": "Six years later, in the new battlefield of short videos, Vine, with its $30 million, preferred to hang out with its big-shot friends. The view counts were always biased towards celebrities, making it difficult for ordinary people's work to be seen. Meanwhile, across the ocean, Musical.ly, with no money or resources, placed casual creations from everyday users on its homepage, and the users did exceed expectations. From \"Lip Sync Battle\" to \"Don't Judge Challenge\", this group of people repeatedly pushed the boundaries of short video content creation. Not long after, users propelled Musical.ly to the top of the download charts, and it then has a brighter new name – TikTok.",
  "cha1_c10": "In the dead of night, I pored over biographies of successful enterprises, sifting through the cacophony of winner-takes-all noise on financial media to piece together these stories. I'm striving to relay them to you in a different way, one that doesn't just highlight the smooth sailing success of genius founders or the shrewdness of big capital. I abhor exaggerating the triumphs of these gargantuan companies. However, if you can focus on \"users\" as the protagonist of these tales, then it is all worth it. You have witnessed the creators of miracles, and you have come to know that \"users\" are not just vague concepts but actual living beings. I hope you also realize that those dubbed as users are none other than you and me.",
  "cha1_c11": "It's us, the users, who enable every product to grow, until it becomes so big that they barely give us another glance.",
  "cha1_c12": "It's us, the users, who make every company prosper, even though they never voluntarily share their joy with us.",
  "cha1_c13": "It's us, the users, who pen one wealth story after another, creating new billionaires with every stroke of the pen.",
  "cha1_c14": "But what have we, the users, truly gained?",
  "cha1_c15": "Just like you, all I want is to get back what we have lost.",
  "cha1_c16_1": "Right now, you are one of\u00A0",
  "cha1_c16_1_p": "Right now, you are one of",
  "cha1_c16_2": "\u00A0people reading this passage at the same time. Since its release in UserDAO\u00A0",
  "cha1_c16_2_p": "people reading this passage at the same time. Since its release in UserDAO",
  "cha1_c16_3": "\u00A0hours ago,\u00A0",
  "cha1_c16_3_p": "hours ago,",
  "cha1_c16_4": "\u00A0partners have joined us. A group of wealth creators who have never received any returns are coming together from different time zones in UserDAO. They are trying to collaboratively find a way to give back wealth to the users and to themselves. ",
  "cha1_c16_4_p": "partners have joined us. A group of wealth creators who have never received any returns are coming together from different time zones in UserDAO. They are trying to collaboratively find a way to give back wealth to the users and to themselves. ",
  "cha1_c17": "Don't miss out on what is rightfully yours. Click\u00A0",
  "cha1_c18": "here ",
  "cha1_c19": "\u00A0to claim it.",
  "cha1_p1": "Profile pages of Facebook users and MySpace users had distinctly different styles.",
  "cha1_p2": "Twitter, previously unknown, took the SXSW by storm and won the Interactive Web Awards.",
  "cha1_p3": "Musical.ly在2018年8月正式融入TikTok",
  "====== c2 ========": "",
  "======= c2 =======": "",
  "======== c2 ======": "",
  "========= c2 =====": "",
  "cha2_c1": "Last week, my dear friend Julia was laid off. We had worked together for two years. She was not a prodigy, but she was certainly a skilled concept artist. As you might have guessed, her position was filled not by another concept artist, but by the 24/7 tireless Midjourney V5. Indeed, everything I have seen about AI has made me realize even more that it is increasingly urgent to give back wealth to users.",
  "cha2_c2": "You may be familiar with the human verification system, reCAPTCHA, which is often used for logging into online accounts. However, you may not know that every road sign, zebra crossing, and intersection that requires your judgment to identify is content that reCAPTCHA's AI model cannot accurately recognize. Every time you perform a human verification, you are helping the AI to annotate data and make it more effective.",
  "cha2_c3": "In fact, image annotation is already the second task assigned to us by reCAPTCHA. During its v1 stage from 2007 to 2018, we helped computers digitalize over 1/5 of the content in more than 130 million books by entering words during human verification, while books are an important source of training data for the  GPT series models.",
  "cha2_p1": "reCAPTCHA was asking the user to identify all the road signs in the image.",
  "cha2_p2": "The dataset for training GPT-3: WebText2 is a web scrap of outbound links from\u000A high-scoring Reddit submissions.",
  "cha2_c4": "According to OpenAI's official paper, GPT-3's training data comes not only from books but also from Wikipedia (3%) and Reddit (22%). If you've ever filled out captchas on reCAPTCHA, reviewed entries on Wikipedia, or posted on Reddit, would you be proud of your contribution to the technological trend? Honestly, I would be. However, at the same time, when ChatGPT Plus charges me a monthly upgrade fee of $20 and when I see OpenAI's ambitious goal of achieving $20 trillion in profit, I'm reminded of the unfinished stories of Facebook, Twitter, and TikTok, and the image of wealth creators who have gained nothing.",
  "cha2_c5": "Give back wealth to users and give back wealth to ourselves -- I am well aware that proposing ideas that benefit the majority is always easier to receive praise and support, but at least for now, I hope that we don't lose sight of prudence. I hope that together, we take another look at this world.",
  "cha2_c6": "We saw 18th-century England, where the world’s first system of intellectual property protection was just established, which allowed scientists to benefit from their research achievements, thus laying the foundation for the first industrial revolution.",
  "cha2_c7": "We saw 19th-century Japan, where the Meiji Restoration abolished the feudal land tenure system and returned crops grown by farmers to their rightful owners, resulting in a rapid 20-year expansion of Japanese agriculture.",
  "cha2_c8": "We saw 20th-century China, where Reform and Opening-up enabled private business owners to gradually acquire ownership of their own companies, paving the way for an economic miracle which had a profound impact on the world.",
  "cha2_c9": "It has been witnessed that, throughout human history, whenever wealth was given back to its creators, incentivized individuals are always provided the conditions and motivations to create greater well-being.",
  "cha2_c10": "It has also been witnessed that today we, the users of the digital age, are exactly the same as those \"inventors without protection\", those \"peasants without land\" and those \"entrepreneurs without authorization to make money\" – we are also confronted with wealth that we have created but cannot enjoy, and are also calling for a change to the production relations.",
  "cha2_c11": "These production relations should be more equitable,  recognizing the significant productivity gains enabled by user data and their everyday usage, and providing us with just compensation.",
  "cha2_c12": "These production relations should be more efficient, fostering a cohesive community among users, products, and companies by effectively incentivizing those who create wealth, thereby working toward shared goals and contributing to the greater social good.",
  "cha2_c13": "These production relations should be more secure and reliable, being rooted in the digital world and being protected by information technology, which makes them everlasting.",
  "cha2_c14": "The grand challenges of our time are rarely resolved quickly. We believe that the ultimate solution to the new production relations of the digital age will require the concerted efforts of many people across various domains in the next decade or two. However, we are more acutely aware that change never happens by simply waiting for it. Thus:",
  "cha2_c15": "We launched UserDAO, seeking to gather the power of pioneers who believe in \"giving back wealth to users\".",
  "cha2_c16": "We founded User Labs, aiming to create products that, in partnership with users, will explore new and more efficient paradigms for wealth distribution.",
  "cha2_c17": "We designed and developed UWT, a novel token tied to product revenue that can be acquired by simply using products, and with a price that keeps rising. We will adopt it as a tool to give back wealth to users.",
  "cha2_c18": "Yes, that is the UWT you have claimed already. ",
  "cha2_c18_1": "Yes, that is the token you have got \u00A0",
  "cha2_c18_2": "\u00A0 of already.\u00A0",
  "cha2_c19": "Click here to claim more of it",
  "====== c3 ========": "",
  "======= c3 =======": "",
  "======== c3 ======": "",
  "========= c3 =====": "",
  "cha3_c1": "Contents",
  "cha3_m1": "Overview",
  "cha3_m2": "Building Intuition",
  "cha3_m3": "Mechanism",
  "cha3_m3_1": "Global Parameters",
  "cha3_m3_2": "UWT Price Formula",
  "cha3_m3_3": "UWT Issuance Formula",
  "cha3_m3_3_1": "Local Parameters",
  "cha3_m3_3_2": "Issuance Formula",
  "cha3_m3_3_3": "Initial Function",
  "cha3_m3_3_4": "Numerical Example-1",
  "cha3_m3_4": "UWT Exchange Formula",
  "cha3_m3_4_1": "Local Parameters",
  "cha3_m3_4_2": "Exchange Formula",
  "cha3_m3_4_3": "Initial Function",
  "cha3_m3_4_4": "Numerical Example-2",
  "cha3_m3_5": "Contract Examination",
  "cha3_m4": "Ecosystem",
  "cha3_m4_1": "User Labs",
  "cha3_m4_2": "Partner Product Integration",
  "cha3_m5": "Conclusion and Vision",
  "cha3_c2": "User Wealth Token (UWT) is a core tool for innovating production relations in the context of the huge change in productivity in the digital age. The sole objective of this tool is to create a self-sustaining economic system that gives back wealth to its users. From its inception, it seeks to eradicate both the exploitation of user wealth by the financial market and the intervention in user wealth by authoritarian forces.",
  "cha3_c3": " UWT is an on-chain asset that users acquire by using products, with a price that keeps rising (price in ETH), and can be exchanged for ETH at any time.",
  "cha3_c4": "In this economic system, UWT and the UWT treasury are crucial.",
  "cha3_c5": "The UWT treasury is an on-chain ETH treasury initiated by UserDAO, that collects funding from all products participating in the UWT ecosystem to support UWT price, guide UWT issuance, and perform UWT exchange.",
  "cha3_c6": "Products that participate in the UWT ecosystem obtain UWT by injecting funds into the UWT treasury, then distribute UWT to their users, thereby giving back wealth to the users, who are the true cornerstone of productivity in the digital age. User Labs, one of the initiators of UserDAO, will have all of its products integrated into the UWT ecosystem, with 51% of its product revenue being allocated to the UWT treasury, abiding by the smart contract.",
  "cha3_c7": "In the following text, we will demonstrate in detail how UWT and the UWT treasury work together to build an economic system that achieves the above goals.",
  "cha3_c8": "Imagine a three-day minimal scenario to build the intuition that UWT keeps rising in price.",
  "cha3_c9": "On the first day, User Labs injects 3 ETH into the UWT treasury, and 3 UWT are issued as the genesis supply.",
  "cha3_c10": "According to the smart contract, ",
  "cha3_c11": ",and users holding UWT can exchange it at any time at this price.",
  "cha3_c11_p": "and users holding UWT can exchange it at any time at this price.",
  "cha3_c10&11": "According to the smart contract,\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0, and users holding UWT can exchange it at any time at this price. ",
  "cha3_c12": "At its genesis, 1 UWT=1 ETH.",
  "cha3_c13": "On the second day, User Labs injects its 2 ETH product revenue into the UWT treasury. Whenever the treasury receives new assets, more UWT needs to be issued to reward users' engagement, with \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0. For easier calculation, let us issue 1 UWT to satisfy this requirement.",
  "cha3_c13_p": "On the second day, User Labs injects its 2 ETH product revenue into the UWT treasury. Whenever the treasury receives new assets, more UWT needs to be issued to reward users' engagement, with ",
  "cha3_c13_p2": "For easier calculation, let us issue 1 UWT to satisfy this requirement.",
  "cha3_c14": "After the new issuance, 1 UWT = 1.25 ETH, leading to a 25% price rise.",
  "cha3_c15": "On the third day, a user initiates an exchange of 3 UWT. To protect the interests of long-term UWT holders and reduce the impact of short-term speculation and money laundering, we need to impose a tax on large or frequent exchanges, and let us take 10% as the tax rate for easier calculation. The user successfully burns 3 UWT and receives 3.375 ETH in the exchange. At the same time, 3.75 Eth×10%=0.375 Eth of tax is paid to the treasury, and all of it is retained in the UWT treasury to provide upward price support for the remaining UWT.",
  "cha3_c16": "After the exchange, 1 UWT = 1.625 ETH, leading to a 30% price rise.",
  "cha3_c17": "Given that USDT is considered a stablecoin tied to the US dollar, we can similarly consider UWT as a risingcoin tied to ETH.",
  "cha3_c18": "UWT price",
  "cha3_c19": "The total value of UWT treasury asset, with price in ETH",
  "cha3_c20": "The total supply of UWT",
  "cha3_c21": "The total number of addresses holding UWT",
  "cha3_c22": "Before every execution of UWT issuance or UWT burning, the smart contract will update the UWT price according to the formula as above.",
  "cha3_c23": "When UWT treasury receives a new ETH payment, it will automatically invoke the function to start the new issuance of UWT in the contract. In other words, whenever there is revenue, there will be new UWT issued to reward users’ engagement. At the same time, the UWT issuance formula will ensure that the UWT price keeps rising, with each issuance.",
  "cha3_c24": "The addition of UWT treasury assets, with price in ETH",
  "cha3_c25": "The amount of newly issued UWT",
  "cha3_c26": "UWT issuance index, δ∈[10% ,90%], which is calculated by a formula jointly determined by the UserDAO members",
  "cha3_c26_p": "UWT issuance index, δ∈[10% ,90%], which is calculated by a formula jointly determined by the UserDAO members",
  "cha3_c27": "UWT treasury growth index, α∈(0 ,0.5%], which is calculated by a formula jointly determined by the UserDAO members",
  "cha3_c27_p": "UWT treasury growth index, α∈(0 ,0.5%], which is calculated by a formula jointly determined by the UserDAO members",
  "cha3_c28": "The issuance index δ and the treasury growth index α will greatly affect the amount of newly issued UWT, ∆I. The calculation formulas for these two parameters will be jointly determined by UserDAO members. We suggest that UserDAO members should consider various metrics that reflect the influence of UWT when governing, such as the number of addresses holding UWT and the growth rate of the UWT treasury. To this end, we have set the initial state of formulas for the issuance index δ and the growth index α as following:",
  "cha3_c28_p": "The issuance index δ and the treasury growth index α will greatly affect the amount of newly issued UWT, ∆I. The calculation formulas for these two parameters will be jointly determined by UserDAO members. We suggest that UserDAO members should consider various metrics that reflect the influence of UWT when governing, such as the number of addresses holding UWT and the growth rate of the UWT treasury. To this end, we have set the initial state of formulas for the issuance index δ and the growth index α as following:",
  "cha3_c29": "Initial Function of",
  "cha3_c30": "Initial Function of",
  "cha3_c31": "The UWT treasury currently holds 313.95 ETH, the UWT contract records a total supply of 24,150 UWT, and there are 100,000 addresses holding UWT in total.",
  "cha3_c32": "At this time, the UWT price",
  "cha3_c33": "The issuance index\u00A0\u00A0",
  "cha3_c34": "Now the treasury receives a 25 ETH payment with the growth index",
  "cha3_c35": "The newly issued amount of UWT",
  "cha3_c36": "After this issuance, the UWT treasury will hold",
  "cha3_c37": "And the total supply of UWT will be\u00A0\u00A0",
  "cha3_c38": "New UWT price\u00A0\u00A0",
  "cha3_c39": ", which brings a 3.85% increase in the UWT price.",
  "cha3_c39_p": "which brings a 3.85% increase in the UWT price.",
  
  "cha3_c38&39": "New UWT price\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0, which brings a 3.85% increase in the UWT price.",
  "cha3_c40":"UWT holders can exchange their tokens for ETH anytime by calling the function to burn UWT in the contract. They can burn a specific amount of UWT and get ETH from the UWT treasury based on the following UWT exchange formula.",
  "cha3_c41":"ETH received in exchange",
  "cha3_c42":"The amount of burned UWT, which UWT holders decide in exchange",
  "cha3_c43":"The time interval between the current exchange and the last exchange, measured in seconds",
  "cha3_c44":"The UWT exchange tax rate,  β∈(0 ,10%], which is calculated by a formula jointly determined by the UserDAO members",
  "cha3_c45":"The UWT exchange tax, i.e. the tax paid to the treasury by UWT holders in exchange",
  "cha3_c46":"From this, we can derive the formula for calculating the UWT exchange tax: T=P×B×β. UserDAO does not take any share of the tax, and all taxes are retained in the treasury to provide upward price support for UWT.",
  "cha3_c47":"The exchange tax rate β will have a significant impact on the increase in UWT price after  exchanges. The calculation formula of β is jointly determined by UserDAO members. We suggest that members of UserDAO pay attention to the distinction between regular user exchange activities and other exchange activities that are detrimental to the UWT ecosystem during governance, and apply differentiated taxation policies to them. For example, exchange activities carried out by regular users should occur at a normal frequency and in a random manner, while the exchange activities of short-term speculators are often characterized by concentration, high frequency, and large transaction amounts. Therefore, we have set the initial state of formulas for exchange tax rate β as following:",
  "cha3_c48":"Initial Function of",
  "cha3_c49":"Let us continue with the numerical settings from Example-1 here: UWT treasury holds 338.95 ETH and the total supply of UWT is 25106.75.",
  "cha3_c50":"UWT price\u00A0\u00A0",
  "cha3_c51":"When a UWT holder initiates a UWT exchange by burning 1000 UWT, and the time interval since the last exchange is 1 hour, ",
  "cha3_c52":"then the exchange tax rate is:",
  "cha3_c51&c52":"When a UWT holder initiates a UWT exchange by burning 1000 UWT, and the time interval since the last exchange is 1 hour, then the exchange tax rate is:",
  "cha3_c53":"The exchange amount",
  "cha3_c54":"Such the exchange tax",
  "cha3_c55":", which was left in the treasury to increase the UWT price.",
  "cha3_c55_p":"which was left in the treasury to increase the UWT price.",
  "cha3_c54&c55":"Such the exchange tax\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0, which was left in the treasury to increase the UWT price.",
  "cha3_c56":"After the exchange, the UWT treasury holds 326.233ETH, and the total supply of UWT is updated to 24106.75 .",
  "cha3_c56_p":"After the exchange, the UWT treasury holds 326.233ETH, and the total supply of UWT is updated to 24106.75",
  "cha3_c57":"Then the updated  price of UWT",
  "cha3_c58":", which shows an increase of 0.24%.",
  "cha3_c58_p":"which shows an increase of 0.24%.",
  "cha3_c57&c58":"Then the updated  price of UWT\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0, which shows an increase of 0.24%.",
  "cha3_c59":"Here is the address of the Payment Contract for User Labs products\u00A0",
  "cha3_c59_p":"Here is the address of the Payment Contract for User Labs products\u00A0",
  "cha3_c60":", and you should verify therein:",
  "cha3_c61":"51% of the revenue from User Labs' products will be injected into the UWT treasury.",
  "cha3_c62":"Here is the address of the UWT Contract\u00A0",
  "cha3_c62_p":"Here is the address of the UWT Contract\u00A0",
  "cha3_c63":", and you should verify therein:",
  "cha3_c64":"The issuance of UWT can only be triggered by treasury's acceptance of funds injection;",
  "cha3_c65":"The increase in the supply of UWT must lead to a rise in its price;",
  "cha3_c66":"The exchange of UWT with treasury must lead to a rise in its price;",
  "cha3_c67":"No one can exchange for assets from the UWT treasury by any means other than burning UWT.",
  "cha3_c68":"User Labs is a product studio established to support the initiation of UserDAO. As the initial practitioners of UserDAO's vision, User Labs is committed to creating a series of high-quality digital products that users favor and can get wealth from. At the same time, in order to maximize its potential value within the UWT ecosystem, all revenue generated by User Labs' products will be distributed, permanently, as following:",
  "cha3_c69":"51% will be injected into the UWT treasury;",
  "cha3_c70":"39% will be injected into the UserDAO Investment Protocol (UIP). At present, UIP's primary task is to accumulate funds to support the issuance of UserDAO's governance token USER. After that, UIP's funds will be invested in partner products that actively participate in the UWT ecosystem via UserDAO members' co-governance, such that the proposition of \"giving back wealth to users\" and the UWT ecosystem itself are contributed to broader adoption;",
  "cha3_c71":"10% will be used for User Labs' product operations.",
  "cha3_c72":"Any product team that embraces the proposition of \"giving back wealth to users\" can propose to integrate their products with UserDAO. We suggest that they specify the amount of injected funds into the UWT treasury and their rules for distributing the UWT they receive to users in their proposal. Members of UserDAO will vote on the proposal. After the proposal is approved, the product would obtain permission to inject its funds into UWT treasury, then obtain UWT by injecting its funds into UWT treasury, and finally distribute UWT to its users.",
  "cha3_c73":"We believe that:",
  "cha3_c74":"UWT, as a risingcoin tied to ETH, holds greater value than ETH in terms of airdrops and incentives;",
  "cha3_c75":"Products that choose to integrate with UWT will immediately receive support and engagement from all UserDAO members;",
  "cha3_c76":"By implementing the proposition of \"giving back wealth to users\", any product will gain a relative advantage in competition with its peers and win user support.",
  "cha3_c77":"UWT provides a new form of on-chain asset that users acquire by simply using products, with a price that keeps rising, and can be exchanged for ETH at any time. In comparison to other tokens, UWT can achieve the following goals:",
  "cha3_c78":"The wealth generated by products is shared genuinely and continuously among all users;",
  "cha3_c79":"Users can accumulate wealth simply by using products, without the need to invest, buy, or pay any other economic expenses;",
  "cha3_c80":"Users’ wealth is protected from exploitation due to hype-induced price volatility, while users' usage is not disturbed by financial cycles as well.",
  "cha3_c81":"We hold the belief that any product that generates wealth through network effects among users can convey a clear set of values, which is that we recognize the value of user usage, to all its users and potential users by engaging in the UWT ecosystem. The proposition of “giving back wealth to users” will lead to a larger, more engaged, and more innovative user base, as well as a more friendly and responsible use of products. These products will achieve greater success in the huge change in productivity in the digital age. Starting with products launched by User Labs, we aim to practice and prove this proposition.",
  "cha3_c82":"We can't wait to achieve wealth, fairness, and freedom within the UWT ecosystem by using products.",
  "cha3_c83":"Click here to claim more UWT",
  
  
  
  
  
  "====== c4 ========": "",
  "======= c4 =======": "",
  "======== c4 ======": "",
  "========= c4 =====": "",
  "cha4_c1": "This is the countdown to the end of current airdrop, as well the moment when you will reveal the final price of your",
  "cha4_c1_1": "",
  "cha4_c2": "\u00A0UWT ---- The UWT issuance mechanism, which ensures a constantly rising price, is what we will be putting to use immediately.",
  "cha4_c3_1": "The free exchange between UWT and ETH will start after the countdown ends. During this period, for every 1000 new UWT holders, I will add an extra 2 ETH to the treasury. Yes, as more people join us, there will be a larger UWT treasury, a higher UWT price, and more wealth for everyone. Until now, the first 1000 UWT holders have seen a\u00A0",
  "cha4_c3_1_p": "The free exchange between UWT and ETH will start after the countdown ends. During this period, for every 1000 new UWT holders, I will add an extra 2 ETH to the treasury. Yes, as more people join us, there will be a larger UWT treasury, a higher UWT price, and more wealth for everyone. Until now, the first 1000 UWT holders have seen a\u00A0",
  "cha4_c3_2": "\u00A0increase in price. As for me, I am currently busy topping up the treasury for you and your friends. The genesis price is determined by everyone together. We have all but price cap.",
  "cha4_c3_2_p": "\u00A0increase in price. As for me, I am currently busy topping up the treasury for you and your friends. The genesis price is determined by everyone together. We have all but price cap.",
  "cha4_c4": "I believe in the power of users, and I believe that we are trapped in a cage by the wrong way of distributing wealth. Once the shackles are broken, the incoming flood will nurture new fertile soil.",
  "cha4_c5": "I believe in the power of the industry, and I believe WEB3.0 will no longer be associated with stories between “rug-pullers” and “suckers” someday. I believe that by reshaping production relations to be fair and effective, it will bring changes to the world.",
  "cha4_c6": "I believe in the power of technology, and I believe that our yesterday's wild imaginings, today's reluctant actions, will finally become tomorrow's routine. I believe that more efficient and reliable decentralized solutions belong to every individual living in the digital age.",
  "cha4_c7": "I believe in the power of action. When a person is unsure of where the road beneath their feet may lead, it means they have climbed higher than ever before. I believe in our hands,  in the journey we have embarked upon together, and in the call to \"give back wealth to users\" that deserves to be heard by more people.",
  "cha4_c8": "Share the manifesto with your family and friends",
  "cha4_c8_p": "Share the Manifesto",
  "====== egg ========": "",
  "======= egg =======": "",
  "======== egg ======": "",
  "========= egg =====": "",

  "egg_tips": "Hold on, the egg is hatching...",
  "egg_c1": "Sizzle is a run-of-the-mill plugin for Twitter, and it is quite gentle.",
  "egg_c2": "Its primary function is to help ordinary people stage parades on the Twitter profile pages of big names.",
  "egg_c3": "Yes, parades.",
  "egg_c3_1": "Plant our message to speak out on their page",
  "egg_c3_2": "like a striking banner dominating this land",
  "egg_c4": "The big screen in the square beams your declaration",
  "egg_c5": "as the crowds keep swelling in",
  "egg_c6": "Let's smash their masks together",
  "egg_c7": "The haughty flaunt their phony facade",
  "egg_c8": "while the jolly stay in the shade.",
  "egg_c9": "The tedious status quo yearns for a change",
  "egg_c10": "Some refused to let their voice be crowded in the comment section",
  "egg_c11": "while ordinary people should have more than just the right to give a like or stay quiet",
  "egg_c12": "As the Airdrop ends, the game begins.",
  "egg_c13": "Note: 51% of Sizzle’s revenue will be injected into UWT treasury to support its rising price and new issuance. ",
  "egg_c13_1": "Note: 51% of Sizzle’s revenue will be injected into UWT treasury to support its rising price",
  "egg_c13_2": " and new issuance. Don't miss the launch at the end of the countdown, and visit ",
  "egg_c14": "Don't miss the launch at the end of the countdown, and visit",
  "egg_c15": "\u00A0\u00A0to learn more about Sizzle."





    
}